import { React, useState } from "react";
import HeroSlider from "../Slider/HeroSlider";
import TextTransition, { presets } from "react-text-transition";
import DiagnosisModal from "../Modal/DiagnosisModal";
import { Link } from "react-router-dom";

const data = {
    bgImg: "images/hero-bg.jpg",
    bgShape: "shape/hero-shape.png",
    sliderImages: [
        {
            img: "images/hero-img.png",
        },
        {
            img: "images/hero-img1.png",
        },
        {
            img: "images/hero-img2.png",
        },
    ],
    title: ["Dentistas", "Clinicas"],
};

const Hero = () => {
    const bgImg = data.bgImg;
    const bgShape = data.bgShape;
    const sliderImages = data.sliderImages;
    const title = data.title;

    const [index] = useState(0);

    // useEffect(() => {
    //     const intervalId = setInterval(
    //         () => setIndex((index) => index + 1),
    //         3000
    //     );
    //     return () => clearTimeout(intervalId);
    // }, []);

    return (
        <div
            id="home"
            className="st-hero-wrap st-gray-bg st-dynamic-bg overflow-hidden st-fixed-bg"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="st-hero st-style1">
                <div className="container">
                    <div className="st-hero-text">
                        <div className="st-height-b40 st-height-lg-b40" />
                        <h1 className="st-hero-title cd-headline slide">
                            Conecta en cosa de
                            <br />
                            minutos con
                            <span className="cd-words-wrapper">
                                <TextTransition springConfig={presets.wobbly}>
                                    <span className="st-transition-title">
                                        {title[index % title.length]}
                                    </span>
                                </TextTransition>
                            </span>
                        </h1>
                        <div className="st-hero-subtitle">
                            Obtén tu evaluación y orientacion dental en linea,
                            de manera rapida y profesional. <br />
                            Con Odontify acceder a atención dental es muy fácil.
                        </div>
                        <div className="st-hero-btn">
                            <DiagnosisModal />
                            <Link
                                style={{ marginLeft: 10 }}
                                className="st-btn st-style1 st-color1 st-smooth-move"
                                to={"/booking"}
                            >
                                Agendar Consulta online
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="st-hero-social-group">
                    <div className="container">{/* <Social /> */}</div>
                </div>
            </div>
            <HeroSlider data={sliderImages} />
            <div className="st-hero-shape">
                <img src={bgShape} alt={bgShape} />
            </div>
        </div>
    );
};

export default Hero;
